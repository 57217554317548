<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>套图</div>
                </div>

                <div class="admin_main_block_right">
                    <div>
                        <el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button>
                    </div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form label-width="100px" ref="info" :model="info">

                    <el-form-item label="分类名称"  prop="F_VR_MINGC">
                        <el-select style="width:300px" v-model="info.F_IN_FENLID" placeholder="请选择分类名称" @change="selectChange()">
                            <el-option label="请选择分类名称" :value="0"></el-option>
                            <el-option v-for="(v,k) in info['fenl']" :label="v.F_VR_MINGC" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="昵称" prop="F_VR_NIC"
                                  :rules="[{required:true,message:'昵称不能为空',trigger: 'blur' }]">
                        <el-input placeholder="请输入昵称" v-model="info.F_VR_NIC"></el-input>
                    </el-form-item>
                    <el-form-item label="个性签名" prop="F_VR_GEXQM"
                                  :rules="[{required:true,message:'个性签名不能为空',trigger: 'blur' }]">
                        <el-input placeholder="请输入个性签名" v-model="info.F_VR_GEXQM"></el-input>
                    </el-form-item>




                    <el-form-item label="头像" prop="thumb">
                        <el-upload class="avatar-uploader" :action="$api.fileUpload" :headers="upload_headers"
                                   :show-file-list="false" :on-success="handleAvatarSuccess">
                            <img v-if="info.F_VR_TOUX" :src="info.F_VR_TOUX" class="avatar-upload">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="顶部图" prop="thumb">
                        <el-upload class="avatar-uploader" :action="$api.fileUpload" :headers="upload_headers"
                                   :show-file-list="false" :on-success="handleAvatarSuccesss">
                            <img v-if="info.F_VR_DINGBT" :src="info.F_VR_DINGBT" class="avatar-upload">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <el-button @click="resetForm('info')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                edit_id: 0,
                info: {},
                roles_list: [],
                roles: [],
                upload_headers: {},
            };
        },
        watch: {},
        computed: {},
        methods: {
            resetForm: function (e) {
                this.$refs[e].resetFields();
            },
            submitForm: function (e) {
                let _this = this;

                // 验证表单
                this.$refs[e].validate(function (res) {
                    if (res) {
                        //  判断是Add 或者 Edit
                        let url = _this.$api.addTgTaotlb;
                        if (_this.edit_id > 0) {
                            url = _this.$api.editTgTaotlb + _this.edit_id;
                        }
                        // Http 请求
                        _this.$post(url, _this.info).then(function (res) {
                            if (res.code == 200) {
                                _this.$message.success("编辑成功");
                                _this.$router.go(-1);
                            } else {
                                _this.$message.error("编辑失败");
                            }
                        });
                    }
                });
            },
            get_users_info: function () {
                let _this = this;
                this.$get(this.$api.editTgTaotlb + this.edit_id).then(function (res) {
                    _this.info = res.data;
                })
            },
            get_adv_list:function(){
                let _this = this;
                this.$get(this.$api.addTgTaotlb).then(function(res){
                    _this.info = res.data;
                })

            },
            handleAvatarSuccess(res) {
                this.info.F_VR_TOUX = res.data.url;
                this.info.F_VR_TOUXTB = res.data.thumb;
                this.$forceUpdate();
            },
            handleAvatarSuccesss(res) {
                this.info.F_VR_DINGBT = res.data.url;
                this.info.F_VR_DINGBTTB = res.data.thumb;
                this.$forceUpdate();
            },
        },
        created() {
            this.upload_headers.Authorization = 'Bearer ' + localStorage.getItem('token'); // 要保证取到
            // 判断是否是编辑
            if (!this.$isEmpty(this.$route.params.F_IN_ID)) {
                this.edit_id = this.$route.params.F_IN_ID;
            }
            if (this.edit_id > 0) {
                this.get_users_info();
            }else{
                this.get_adv_list();
            }
        },
        mounted() {
        }
    };
</script>
<style lang="scss" scoped>

</style>